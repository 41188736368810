header {
    grid-area: header;
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Logo {
    max-width: 225px;
}

.AuthContainer {
    justify-self: center;
}