.Container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    box-sizing: border-box;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/giftyrates.appspot.com/o/media%2Fimg%2Fregister.jpg?alt=media&token=6291bbfb-8617-4e92-b77b-610881612ea9);
    background-size: cover;
    overflow-y: auto;
}

.TextField {
    margin-bottom: 20px !important;
}

.SignInBtn {
    width: 100%;
}

.BoxBtnUp {
    width: 100%;
}

.errorText {
    font-size: 14px;
    font-weight: 100;
    color: red;
}

.Inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
}