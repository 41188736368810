.Container {
    grid-area: for-who;
    text-align: center;
    margin-top: 5vh;
    overflow-x: hidden;
    height: 150px;
}

.Workers {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.Workers::-webkit-scrollbar {
    display: none;
}

.Worker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2vw;
    font-weight: bold;
    white-space: nowrap;
}
