.Button {
    border: none;
    background-color: transparent;
    outline: none;
    height: 44px;
    width: 44px;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #000;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.Button::before {
    background-color: transparent;
    content: "";
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 22px;
    transition: 200ms all;
}

.Button:active:before {
    background-color: black;
}

.Button.disabled {
    color: gray;
    cursor: auto;
}
