.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;

    h3 {
        font-weight: bold;
    }
}

.ErrorText {
    font-size: 14px;
    color: red;
    margin-top: 10px;
}
