.Container {
    grid-area: features;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2.5vh;
    margin-left: 5vh;
    margin-right: 5vh;
    border-top: 4px solid #f2f2f2;
    padding-top: 3vh;
    padding-bottom: 1vh;
}