.HeaderImage {
    margin-top: 20px;
    height: 75px;
}

.TextInput {
    margin-bottom: 10px;
}

.BankDetails {
    text-align: left;
    width: 100%;
    max-width: 400px;
    
    th {
        font-weight: 600;
    }
}

.error {
    font-size: 0.8em;
    font-weight: bold;
    color: red;
    margin: 5px 0;
}