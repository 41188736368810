.Container {
    background: #fff;
    border: none;
    border: 2px solid lightgray;
    border-radius: 8px;
    transition: 0.2s;
    padding: 0 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    text-align: left;
}

.Container:focus-within {
    background: white;
    border: 2px solid #05C3DD;
}

.Container.error {
    border-color: red !important;
}

.Input {
    background: none;
    border: none;
    outline: none;
    color: #000 !important;
    font-size: 16px;
    margin: 0;
    width: 100%;
    padding: 10px 8px;
    font-weight: 500;
}

.Input::placeholder {
    color: rgb(98, 98, 98)
}

.ErrorSpan {
    font-size: 12px;
    color: red;
    margin-top: 10px;
    position: absolute;
    bottom: -20px;
    width: 100%;
    padding-right: 24px;
    box-sizing: border-box;
    opacity: 0;
    transition: 0.3s opacity;
    white-space: nowrap;
}

.ErrorSpan.error {
    opacity: 1;
}