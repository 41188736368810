.Container {
    grid-area: steps;
    text-align: center;
    margin-top: 2.5vh;
    padding-left: 5vw;
    padding-right: 5vw;
}

.Grid {
    display: grid;
}

@media (max-width: 800px) {
    .Grid {
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: 25px;
    }
}

@media (min-width: 800px) {
    .Grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8vw
    }
}
