.UsernameStatus {
    margin-left: 24px;
    color: #4bb543;
    transition: 0.3s all;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 6px;
    min-height: 20px;
    position: absolute;
    bottom: -12px;
}

.UsernameStatus.error {
    color: red;
}