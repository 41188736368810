.Container {
    cursor: pointer;
    color: #006CFF;
    font-weight: bold;
    padding: 5px 12px;
    font-size: 0.9em;
    border-radius: 10px;
    background-color: transparent;
    transition: 0.1s all;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
}

.Container:active {
    background-color: #CBCBCB;
}