.Container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    box-sizing: border-box;
}

.errorText {
    font-size: 14px;
    font-weight: 100;
    color: red;
}
