.loader {
    height: 16px;
    width: 16px;
    color: #fff;
    position: relative;
    display: inline-block;
    border: 4px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite; }
  
  @keyframes rotate {
    0% {
      transform: rotate(0); }
    100% {
      transform: rotate(360deg); } }