.footer {
    grid-area: footer;
    width: 100%;
    background-color: #f2f2f2;
    padding: 5vh 10%;
    box-sizing: border-box;
    border-top: 1px solid #d3d3d3;
    color: #4b4b4b;

    .SiteInfo {
        display: flex;
        margin-bottom: 5vh;

        .Info {
            flex: 4;
            padding-right: 2.5%;
        }

        .About {
            flex: 1;
            padding-right: 2.5%;
        }

        .Col {
            flex: 1
        }

        .Logo {
            width: 175px;
        }

        a {
            display: block;
            margin-bottom: 5px;
            text-decoration: none;
            color: inherit;
            font-size: 0.9em;
            transition: 0.2s;
        }

        a:hover {
            color: #05c3dd
        }
    }
}

@media (max-width: 800px) {
    .SiteInfo {
        flex-direction: column;
        gap: 2vh
    }
}
