.Container {
    grid-area: pricing;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    padding-top: 2vh;
    padding-bottom: 5vh;
}

.Card {
    background-color: white;
    box-shadow: #00000029 0 1px 6px;
    border-radius: 10px;
    padding-top: 10px;
    margin-top: 20px;
    min-width: 300px;
    max-width: 350px;
    width: 90%;
    overflow: hidden;

    .Main {
        border-top: 1px solid #ebebeb;
        padding: 0 2.5%;
        padding-top: 14px;
        line-height: 22px;

        .desc {
            font-size: 1em;
            padding-top: 10px;
            padding-left: 4%;
            padding-right: 4%;
        }

        ul {
            list-style: none;

            li {
                position: relative;
                text-align: left;
                margin-bottom: 20px;
                font-size: 0.95em;
                padding-left: 30px;
                padding-right: 20px;
            }
        }

        .icon {
            background-color: #b4f6ff;
            color: #00b1c8;
            height: 30px;
            width: 30px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: -20px;
            top: -2px;
        }
    }
    
    .Footer {
        background-color: #f6f9fc;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.2s all;
    }

    .Footer:hover{
        background-color: #05c3dd;
        color: white;
    }
}
