@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

html {
  height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.row.a {
  align-items: center;
}

.col.a {
  align-items: center;
  justify-content: center;
}

.muted {
  font-size: 0.8em;
  color: gray;
}
