.Container {
    grid-area: preview;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 70vh;
    margin: 30px 0;
}

.DemoQR {
    position: absolute;
    height: 150px;
    z-index: 10;
    border-radius: 10px;
    transform: rotate(8deg);
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    top: 20%;
    right: 15%;
    transition: 0.4s;
}

.DemoQR:hover {
    transform: rotate(0deg) scale(1.1);
}

.AnimatedCircle {
    height: 50vh;
    width: 50vh;
    position: absolute;
    bottom: -5%;
    border-radius: 50%;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
    transition: 0.4s;
}

.AnimatedCircle:hover {
    transform: scale(1.05);
}

.Image {
    height: 56.8vh;
    position: absolute;
}

.Outline {
    height: 60vh;
    position: absolute;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media (max-width: 700px) {
    .DemoQR {
        top: unset;
        bottom: -5%;
        right: 3%;
    }
}