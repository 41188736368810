.Img {
    width: 35vw;
    height: 35vw;
    border-radius: 50%;
    border: 2px solid lightgray;
    margin-bottom: 5px;
    max-width: 250px;
    max-height: 250px;
    position: relative;
    z-index: 1;
    margin-top: 10px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}

.InputDisplayName {
    all: unset;
    font: inherit;
    width: 100%;
    background: none;
    text-align: center;
    resize: auto;
    white-space: wrap;
    overflow: hidden;
    height: 35px;
    word-break: break-all;
}

.InputDisplayName::placeholder {
    color: #c9c9c9;
}

.InputAboutText {
    all: unset;
    font: inherit;
    width: 80%;
    align-self: center;
    text-align: center;
    margin: 10px 0;
    height: 100px;
}

.TipContainer {
    background-color: #fff;
    width: 90%;
    align-self: center;
    box-shadow: #00000029 0 3px 6px;
    border-radius: 10px;
    padding: 0 2.5%;
    box-sizing: border-box;
    min-height: 100px;
    transition: 0.2s height;
    position: relative;
    padding-bottom: 20px;
}

.TipCircles {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 12px;
}

.CustomInputError {
    color: red;
    font-size: 14px;
    text-align: center;
    margin: 6px 5px;
}

.EditCircle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #05c3dd;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
