.Container {
    background-color: white;
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: #00000029 0 1px 6px;
    width: 95%;
    align-self: center;
    padding: 12px 0;
    cursor: pointer;
}

.ImgContainer {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    background-color: lightgray;
    border: 2px solid lightgray;
    flex: 0 0 auto;
    display: flex;
    margin-left: 4%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.AboutText {
    color: gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
}
