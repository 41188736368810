.Form {
    box-sizing: border-box;
    background: #fff;
    max-width: 500px;
    width: 100%;
    min-height: 400px;
    border-radius: 10px;
    box-shadow: 4px 4px 50px 29px rgba(34, 60, 80, 0.12);
    display: flex;
    flex-direction: column;
    padding: 50px;
    padding-bottom: 25px;
    margin-bottom: 30px;
    position: relative;
    align-self: flex-start;
}

.Logo {
    width: 140px;
    cursor: pointer;
}
