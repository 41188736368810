.BackContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.5s all;
    pointer-events: none;
    z-index: 120;
}

.BackContainer.active {
    opacity: 1;
    pointer-events: all;
}

.Modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    border-radius: 10px;
    background: #fff;
    box-shadow: #00000029 0 1px 6px;
    max-width: 300px;
    width: 100%;
    min-height: 400px;
    max-height: 80%;
    padding: 0 5px;
    box-sizing: border-box;
    overflow-y: auto;
    transform: scale(0.5);
    transition: 0.5s all;
}

.Modal.active {
    transform: scale(1);
}
