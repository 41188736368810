.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    max-width: 220px;
    margin-bottom: 20px;

    h3 {
        margin-bottom: 0;
    }

    p {
        margin-top: 10px;
    }

    svg {
        transition: 0.2s;
    }

    svg:hover {
        transform: scale(1.05);
    }
}
