.Container {
    grid-area: about;
    text-align: center;
    margin-top: 2vh;
    margin-left: 5vw;
    margin-right: 5vw;
}

@media (min-width: 800px) {
    .Container {
        margin-left: 30vw;
        margin-right: 30vw;
    }
}
