.Container {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 50px;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
}

.QRCode {
    border-radius: 10px;
    margin-bottom: 10px;
}