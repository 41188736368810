.Container {
    grid-area: badges;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;

    img {
        width: 100px;
        margin: 0 5%;
    }
}