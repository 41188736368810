.Container {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 50px;
    box-sizing: border-box;
    position: relative;
}

.Img {
    width: 50vw;
    height: 50vw;
    border-radius: 50%;
    border: 2px solid lightgray;
    margin-bottom: -25px;
    max-width: 250px;
    max-height: 250px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.WhiteContainer {
    background-color: #fff;
    width: 90%;
    align-self: center;
    box-shadow: #00000029 0 3px 6px;
    border-radius: 10px;
    padding: 0 2.5%;
    box-sizing: border-box;
    min-height: 200px;
    transition: 0.2s height;
    position: relative;
}

.TipCircles {
    width: 100%;
    margin-top: 20px;
    overflow-y: auto;
    padding-bottom: 20px;
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.TipCircle {
    cursor: pointer;
    flex: 1 0 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid lightgray;
    transition: 0.5s all;
    -webkit-tap-highlight-color: transparent;
}

.TipCircle.selected {
    border-color: #05c3dd;
    color: #05c3dd;
}

.CustomInput {
    border: none;
    outline: none;
    -webkit-appearance: none;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    align-self: center;
    font-size: 18px;
    text-align: center;
}

.CustomInputError {
    color: red;
    font-size: 14px;
    text-align: center;
    margin: 6px 5px;
}

.LabelAnonymous {
    align-self: center;
}

.disclaimer {
    font-size: 0.7em;
    color: gray;
    text-align: center;
    width: 100%;
    position: fixed;
    bottom: 2.5%;
    z-index: 999;

    a {
        color: inherit;
    }
}