.Container {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-bottom: 50px;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    margin: 0 5%;
}

.TopContainer {
    width: 90%;
    align-self: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid gray;
}
