.Container {
    width: 90%;
    align-self: center;
    border-radius: 10px;
    background-color: #079BF5;
    color: #FFF;
    padding: 8px 5%;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    word-break: break-all;
}