.Container {
    width: 90%;
    margin: 10px auto;
    background-color: lightgray;
    padding: 10px 5%;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;
}

.Text {
    font-size: 0.8em;
    margin-left: 10px;
    flex: 1;
}