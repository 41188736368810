.Container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 800px;
    margin: 0 auto;
    position: relative;

    main {
        flex: 1;
        background-color: #f2f2f2;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 40px;
    }

    header {
        display: flex;
        align-items: center;
        width: 90%;
        align-self: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .Logo {
        width: 50%;
        object-fit: cover;
        padding-top: 4px;
        margin-left: 5%;
    }

    .TopSheet {
        position: absolute;
        width: 100%;
        height: 250px;
        background-color: #fff;
        padding: 25px 10px;
        padding-top: 10px;
        box-sizing: border-box;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        box-shadow: #00000029 0 1px 6px;
        top: -300px;
        transition: 0.5s all;
        z-index: 100;
        display: flex;
        flex-direction: column;

        .SheetContainer {
            flex: 1;
            margin-top: 10px;
        }
    }

    .TopSheet.active {
        top: 0;
    }
}
