.Button {
    background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: white;
    width: 100%;
    min-height: 40px;
    padding: 8px 16px;
    border-radius: 10px;
    transition: 0.2s all;
    -webkit-tap-highlight-color: transparent;
    font-weight: bold;
    font-size: 16px;
}

.Button:active {
    filter: brightness(0.8);
}

.Button.disabled {
    background-color: lightgray !important;
}

.Button.disabled:active {
    filter: none !important
}

.InnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: inherit;
}