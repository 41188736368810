.Container {
    width: 100%;
    padding: 0 15px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.CardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
}

.CardForm {
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.CardBox {
    border: 2px solid lightgray;
    border-radius: 8px;
    padding: 12px 0;
    padding-left: 4px;
    margin-bottom: 10px;
    transition: 0.2s;
}

.CardBox.active {
    border-color: #05C3DD;
}

.ErrorSpan {
    display: block;
    font-size: 12px;
    color: red;
    margin-top: 10px;
}

.info {
    font-size: 0.8em;
    color: gray;
    text-align: center;
    margin-top: 10px;
}
