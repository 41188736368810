.Container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    box-sizing: border-box;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/giftyrates.appspot.com/o/media%2Fimg%2Flogin.jpg?alt=media&token=011e505a-d3fc-4e30-a2a5-e600b5aab79d);
    background-size: cover;
}

.errorText {
    font-size: 14px;
    font-weight: 100;
    color: red;
}
