h1 {
  font-weight: bold;
}

.wrapper {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  display: grid;
  grid-template-areas:
    "header"
    "hero-graphic"
    "hero-text"
    "preview"
    "about"
    "steps"
    "for-who"
    "features"
    "badges"
    "pricing"
    "footer";
}

@media (min-width: 800px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "header  header header"
      "hero-graphic hero-graphic hero-text"
      "preview preview preview"
      "about about about"
      "steps steps steps"
      "for-who for-who for-who"
      "features features features"
      "badges badges badges"
      "pricing pricing pricing"
      "footer footer footer";
  }
}
