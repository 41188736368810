.Container {
    background-color: white;
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: #00000029 0 1px 6px;
    width: 95%;
    align-self: center;
    padding: 12px 0;
}

.Img {
    margin-left: 12px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
    border: 2px solid lightgray;
    margin-left: 4%;
    object-fit: cover;
}

.ImgPlaceholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
    margin-left: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}
