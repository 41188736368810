@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

html {
  height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.row.a {
  align-items: center;
}

.col.a {
  align-items: center;
  justify-content: center;
}

.muted {
  font-size: 0.8em;
  color: gray;
}

.ALink_Container__2ygYc{cursor:pointer;color:#006cff;font-weight:bold;padding:5px 12px;font-size:.9em;border-radius:10px;background-color:rgba(0,0,0,0);transition:.1s all;display:inline-block;-webkit-tap-highlight-color:rgba(0,0,0,0)}.ALink_Container__2ygYc:active{background-color:#cbcbcb}
header{grid-area:header;display:flex;align-items:center;padding-left:2%;padding-right:10%;padding-top:10px;padding-bottom:10px}.Header_Logo__3U3EH{max-width:225px}.Header_AuthContainer__30Lqi{justify-self:center}
.Button_Button__2BYYQ{background:none;color:inherit;border:none;font:inherit;cursor:pointer;outline:inherit;color:#fff;width:100%;min-height:40px;padding:8px 16px;border-radius:10px;transition:.2s all;-webkit-tap-highlight-color:rgba(0,0,0,0);font-weight:bold;font-size:16px}.Button_Button__2BYYQ:active{-webkit-filter:brightness(0.8);filter:brightness(0.8)}.Button_Button__2BYYQ.Button_disabled__1f6YP{background-color:#d3d3d3 !important}.Button_Button__2BYYQ.Button_disabled__1f6YP:active{-webkit-filter:none !important;filter:none !important}.Button_InnerContainer__sDF7L{display:flex;align-items:center;justify-content:center;text-align:center;font:inherit}
.Loader_loader__2hZcM{height:16px;width:16px;color:#fff;position:relative;display:inline-block;border:4px solid;border-radius:50%;border-top-color:rgba(0,0,0,0);-webkit-animation:Loader_rotate__3QseU 1s linear infinite;animation:Loader_rotate__3QseU 1s linear infinite}@-webkit-keyframes Loader_rotate__3QseU{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes Loader_rotate__3QseU{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
.Hero_Graphic__2-Fwh{grid-area:hero-graphic;width:105%;justify-self:center}.Hero_Text__3Bfqc{grid-area:hero-text;justify-self:center;display:flex;flex-direction:column}.Hero_Text__3Bfqc .Hero_content__34Qr9{flex:1 1;display:flex;flex-direction:column;justify-content:center;max-width:320px}.Hero_Arrow__2_P5M{-webkit-animation:Hero_bounce__2ktyS 3s infinite;animation:Hero_bounce__2ktyS 3s infinite;margin:0 auto;height:40px;width:40px;border-radius:50%;display:flex;align-items:center;justify-content:center;border:3px solid #05c3dd;color:#05c3dd}@media(max-width: 800px){.Hero_Text__3Bfqc .Hero_content__34Qr9{margin-bottom:30px}}@-webkit-keyframes Hero_bounce__2ktyS{0%,20%,50%,80%,100%{-webkit-transform:translateY(0);transform:translateY(0)}40%{-webkit-transform:translateY(-30px);transform:translateY(-30px)}60%{-webkit-transform:translateY(-15px);transform:translateY(-15px)}}@keyframes Hero_bounce__2ktyS{0%,20%,50%,80%,100%{-webkit-transform:translateY(0);transform:translateY(0)}40%{-webkit-transform:translateY(-30px);transform:translateY(-30px)}60%{-webkit-transform:translateY(-15px);transform:translateY(-15px)}}
.Preview_Container__YOlWj{grid-area:preview;display:flex;align-items:center;justify-content:center;position:relative;height:70vh;margin:30px 0}.Preview_DemoQR__I9s6w{position:absolute;height:150px;z-index:10;border-radius:10px;-webkit-transform:rotate(8deg);transform:rotate(8deg);box-shadow:0 3px 10px rgba(0,0,0,.2);top:20%;right:15%;transition:.4s}.Preview_DemoQR__I9s6w:hover{-webkit-transform:rotate(0deg) scale(1.1);transform:rotate(0deg) scale(1.1)}.Preview_AnimatedCircle__Hs0im{height:50vh;width:50vh;position:absolute;bottom:-5%;border-radius:50%;background:linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);background-size:400% 400%;-webkit-animation:Preview_gradient__OJemA 5s ease infinite;animation:Preview_gradient__OJemA 5s ease infinite;transition:.4s}.Preview_AnimatedCircle__Hs0im:hover{-webkit-transform:scale(1.05);transform:scale(1.05)}.Preview_Image__3H1Yn{height:56.8vh;position:absolute}.Preview_Outline__2qrUG{height:60vh;position:absolute}@-webkit-keyframes Preview_gradient__OJemA{0%{background-position:0% 50%}50%{background-position:100% 50%}100%{background-position:0% 50%}}@keyframes Preview_gradient__OJemA{0%{background-position:0% 50%}50%{background-position:100% 50%}100%{background-position:0% 50%}}@media(max-width: 700px){.Preview_DemoQR__I9s6w{top:unset;bottom:-5%;right:3%}}
.About_Container__2gCEj{grid-area:about;text-align:center;margin-top:2vh;margin-left:5vw;margin-right:5vw}@media(min-width: 800px){.About_Container__2gCEj{margin-left:30vw;margin-right:30vw}}
.Steps_Container__3qYKx{grid-area:steps;text-align:center;margin-top:2.5vh;padding-left:5vw;padding-right:5vw}.Steps_Grid__3Q45V{display:grid}@media(max-width: 800px){.Steps_Grid__3Q45V{grid-template-rows:repeat(3, 1fr);grid-row-gap:25px}}@media(min-width: 800px){.Steps_Grid__3Q45V{display:grid;grid-template-columns:repeat(3, 1fr);grid-gap:8vw;gap:8vw}}
.StepCard_Container__rNaOu{display:flex;flex-direction:column;align-items:center}h3{font-weight:bold}.StepCard_StepCircle__2HXXN{font-size:24px;width:40px;height:40px;display:flex;align-items:center;justify-content:center;border:3px solid #05c3dd;color:#05c3dd;border-radius:50%}
.ForWho_Container__19IBs{grid-area:for-who;text-align:center;margin-top:5vh;overflow-x:hidden;height:150px}.ForWho_Workers__3hOmF{width:100%;display:flex;overflow-x:scroll;-ms-overflow-style:none;scrollbar-width:none}.ForWho_Workers__3hOmF::-webkit-scrollbar{display:none}.ForWho_Worker__1q9an{display:flex;flex-direction:column;align-items:center;margin:0 2vw;font-weight:bold;white-space:nowrap}
.Features_Container__3kzbS{grid-area:features;text-align:center;display:flex;flex-wrap:wrap;justify-content:space-evenly;margin-top:2.5vh;margin-left:5vh;margin-right:5vh;border-top:4px solid #f2f2f2;padding-top:3vh;padding-bottom:1vh}
.Feature_Container__1Q-Ks{display:flex;flex-direction:column;align-items:center;font-weight:bold;max-width:220px;margin-bottom:20px}.Feature_Container__1Q-Ks h3{margin-bottom:0}.Feature_Container__1Q-Ks p{margin-top:10px}.Feature_Container__1Q-Ks svg{transition:.2s}.Feature_Container__1Q-Ks svg:hover{-webkit-transform:scale(1.05);transform:scale(1.05)}
.Badges_Container__OrEjH{grid-area:badges;display:flex;flex-wrap:wrap;align-items:center;justify-content:center;margin-bottom:5vh}.Badges_Container__OrEjH img{width:100px;margin:0 5%}
.Pricing_Container__jxYlv{grid-area:pricing;text-align:center;display:flex;flex-direction:column;align-items:center;background-color:#f2f2f2;padding-top:2vh;padding-bottom:5vh}.Pricing_Card__1uEEs{background-color:#fff;box-shadow:rgba(0,0,0,.1607843137) 0 1px 6px;border-radius:10px;padding-top:10px;margin-top:20px;min-width:300px;max-width:350px;width:90%;overflow:hidden}.Pricing_Card__1uEEs .Pricing_Main__1CSZA{border-top:1px solid #ebebeb;padding:0 2.5%;padding-top:14px;line-height:22px}.Pricing_Card__1uEEs .Pricing_Main__1CSZA .Pricing_desc__3BBsX{font-size:1em;padding-top:10px;padding-left:4%;padding-right:4%}.Pricing_Card__1uEEs .Pricing_Main__1CSZA ul{list-style:none}.Pricing_Card__1uEEs .Pricing_Main__1CSZA ul li{position:relative;text-align:left;margin-bottom:20px;font-size:.95em;padding-left:30px;padding-right:20px}.Pricing_Card__1uEEs .Pricing_Main__1CSZA .Pricing_icon__1Nm0r{background-color:#b4f6ff;color:#00b1c8;height:30px;width:30px;border-radius:15px;display:flex;align-items:center;justify-content:center;position:absolute;left:-20px;top:-2px}.Pricing_Card__1uEEs .Pricing_Footer__39ZsR{background-color:#f6f9fc;padding:20px 0;display:flex;align-items:center;justify-content:center;cursor:pointer;transition:.2s all}.Pricing_Card__1uEEs .Pricing_Footer__39ZsR:hover{background-color:#05c3dd;color:#fff}
.Footer_footer__1pvk9{grid-area:footer;width:100%;background-color:#f2f2f2;padding:5vh 10%;box-sizing:border-box;border-top:1px solid #d3d3d3;color:#4b4b4b}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z{display:flex;margin-bottom:5vh}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z .Footer_Info__jfiFm{flex:4 1;padding-right:2.5%}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z .Footer_About__2nQuO{flex:1 1;padding-right:2.5%}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z .Footer_Col__2A6K0{flex:1 1}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z .Footer_Logo__NjEha{width:175px}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z a{display:block;margin-bottom:5px;text-decoration:none;color:inherit;font-size:.9em;transition:.2s}.Footer_footer__1pvk9 .Footer_SiteInfo__nZ49z a:hover{color:#05c3dd}@media(max-width: 800px){.Footer_SiteInfo__nZ49z{flex-direction:column;grid-gap:2vh;gap:2vh}}
h1{font-weight:bold}.Home_wrapper__16gUO{height:100%;width:100%;scroll-behavior:smooth;overflow-x:hidden;display:grid;grid-template-areas:"header" "hero-graphic" "hero-text" "preview" "about" "steps" "for-who" "features" "badges" "pricing" "footer"}@media(min-width: 800px){.Home_wrapper__16gUO{grid-template-columns:repeat(3, 1fr);grid-template-areas:"header  header header" "hero-graphic hero-graphic hero-text" "preview preview preview" "about about about" "steps steps steps" "for-who for-who for-who" "features features features" "badges badges badges" "pricing pricing pricing" "footer footer footer"}}
.IconButton_Button__1XvLM{border:none;background-color:rgba(0,0,0,0);outline:none;height:44px;width:44px;border-radius:22px;display:flex;flex-direction:column;align-items:center;justify-content:center;position:relative;color:#000;cursor:pointer;-webkit-tap-highlight-color:rgba(0,0,0,0)}.IconButton_Button__1XvLM::before{background-color:rgba(0,0,0,0);content:"";opacity:.1;position:absolute;top:0;left:0;width:100%;height:100%;z-index:2;border-radius:22px;transition:200ms all}.IconButton_Button__1XvLM:active:before{background-color:#000}.IconButton_Button__1XvLM.IconButton_disabled__3a_Vo{color:gray;cursor:auto}
.Modal_BackContainer__2F4Ac{width:100%;height:100%;position:fixed;top:0;left:0;z-index:10;-webkit-backdrop-filter:blur(2px);backdrop-filter:blur(2px);display:flex;justify-content:center;align-items:center;opacity:0;transition:.5s all;pointer-events:none;z-index:120}.Modal_BackContainer__2F4Ac.Modal_active__3fzEB{opacity:1;pointer-events:all}.Modal_Modal__32mLz{display:flex;flex-direction:column;align-items:center;position:absolute;border-radius:10px;background:#fff;box-shadow:rgba(0,0,0,.1607843137) 0 1px 6px;max-width:300px;width:100%;min-height:400px;max-height:80%;padding:0 5px;box-sizing:border-box;overflow-y:auto;-webkit-transform:scale(0.5);transform:scale(0.5);transition:.5s all}.Modal_Modal__32mLz.Modal_active__3fzEB{-webkit-transform:scale(1);transform:scale(1)}
.UserLayout_Container__3llyI{display:flex;flex-direction:column;height:100%;max-width:800px;margin:0 auto;position:relative}.UserLayout_Container__3llyI main{flex:1 1;background-color:#f2f2f2;height:100%;overflow-y:auto;padding-bottom:40px}.UserLayout_Container__3llyI header{display:flex;align-items:center;width:90%;align-self:center;padding-top:10px;padding-bottom:10px}.UserLayout_Container__3llyI .UserLayout_Logo__2C7Yd{width:50%;object-fit:cover;padding-top:4px;margin-left:5%}.UserLayout_Container__3llyI .UserLayout_TopSheet__1vp2f{position:absolute;width:100%;height:250px;background-color:#fff;padding:25px 10px;padding-top:10px;box-sizing:border-box;border-bottom-left-radius:15px;border-bottom-right-radius:15px;box-shadow:rgba(0,0,0,.1607843137) 0 1px 6px;top:-300px;transition:.5s all;z-index:100;display:flex;flex-direction:column}.UserLayout_Container__3llyI .UserLayout_TopSheet__1vp2f .UserLayout_SheetContainer__1jebd{flex:1 1;margin-top:10px}.UserLayout_Container__3llyI .UserLayout_TopSheet__1vp2f.UserLayout_active__33FK6{top:0}
.ProfileCard_Container__1ESyL{background-color:#fff;margin:10px 0;border-radius:10px;box-shadow:rgba(0,0,0,.1607843137) 0 1px 6px;width:95%;align-self:center;padding:12px 0;cursor:pointer}.ProfileCard_ImgContainer__XTzlI{width:75px;height:75px;border-radius:50%;overflow:hidden;background-color:#d3d3d3;border:2px solid #d3d3d3;flex:0 0 auto;display:flex;margin-left:4%}.ProfileCard_ImgContainer__XTzlI img{width:100%;height:100%;object-fit:cover}.ProfileCard_AboutText__wB9j4{color:gray;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;width:90%}
.Banner_Container__8D_vv{width:90%;align-self:center;border-radius:10px;background-color:#079bf5;color:#fff;padding:8px 5%;box-sizing:border-box;text-align:center;font-weight:bold;font-size:24px;word-break:break-all}
.TextInput_Container__VAh03{background:#fff;border:none;border:2px solid #d3d3d3;border-radius:8px;transition:.2s;padding:0 16px;display:flex;align-items:center;box-sizing:border-box;position:relative;text-align:left}.TextInput_Container__VAh03:focus-within{background:#fff;border:2px solid #05c3dd}.TextInput_Container__VAh03.TextInput_error__1m8T5{border-color:red !important}.TextInput_Input__2Kz5S{background:none;border:none;outline:none;color:#000 !important;font-size:16px;margin:0;width:100%;padding:10px 8px;font-weight:500}.TextInput_Input__2Kz5S::-webkit-input-placeholder{color:#626262}.TextInput_Input__2Kz5S::placeholder{color:#626262}.TextInput_ErrorSpan__3OMzM{font-size:12px;color:red;margin-top:10px;position:absolute;bottom:-20px;width:100%;padding-right:24px;box-sizing:border-box;opacity:0;transition:.3s opacity;white-space:nowrap}.TextInput_ErrorSpan__3OMzM.TextInput_error__1m8T5{opacity:1}
.Payment_Container__MtpIp{width:100%;padding:0 15px;padding-bottom:20px;box-sizing:border-box}.Payment_CardContainer__IpywA{width:100%;height:100%;display:flex;justify-content:center;align-items:center;padding:0 10px 0 10px;box-sizing:border-box}.Payment_CardForm__2rDF-{box-sizing:border-box;width:100%;border-radius:10px;display:flex;flex-direction:column}.Payment_CardBox__1nnrZ{border:2px solid #d3d3d3;border-radius:8px;padding:12px 0;padding-left:4px;margin-bottom:10px;transition:.2s}.Payment_CardBox__1nnrZ.Payment_active__2-NNi{border-color:#05c3dd}.Payment_ErrorSpan__3qGiv{display:block;font-size:12px;color:red;margin-top:10px}.Payment_info__3FOJ_{font-size:.8em;color:gray;text-align:center;margin-top:10px}
.Tip_Container__2qsz0{flex:1 1;min-height:100%;display:flex;flex-direction:column;padding-top:15px;padding-bottom:50px;box-sizing:border-box;position:relative}.Tip_Img__1P6EG{width:50vw;height:50vw;border-radius:50%;border:2px solid #d3d3d3;margin-bottom:-25px;max-width:250px;max-height:250px;overflow:hidden}.Tip_Img__1P6EG img{height:100%;width:100%;object-fit:cover}.Tip_WhiteContainer__3nyKw{background-color:#fff;width:90%;align-self:center;box-shadow:rgba(0,0,0,.1607843137) 0 3px 6px;border-radius:10px;padding:0 2.5%;box-sizing:border-box;min-height:200px;transition:.2s height;position:relative}.Tip_TipCircles__1GYyh{width:100%;margin-top:20px;overflow-y:auto;padding-bottom:20px;grid-gap:10px;gap:10px;display:flex;flex-direction:row}.Tip_TipCircle__1ErC1{cursor:pointer;flex:1 0 65px;height:65px;border-radius:50%;display:flex;flex-direction:column;justify-content:center;align-items:center;border:3px solid #d3d3d3;transition:.5s all;-webkit-tap-highlight-color:rgba(0,0,0,0)}.Tip_TipCircle__1ErC1.Tip_selected__1M0OX{border-color:#05c3dd;color:#05c3dd}.Tip_CustomInput__fVzdo{border:none;outline:none;-webkit-appearance:none;width:80%;height:80%;border-radius:50%;align-self:center;font-size:18px;text-align:center}.Tip_CustomInputError__18Ri3{color:red;font-size:14px;text-align:center;margin:6px 5px}.Tip_LabelAnonymous__3700O{align-self:center}.Tip_disclaimer__YvbE6{font-size:.7em;color:gray;text-align:center;width:100%;position:fixed;bottom:2.5%;z-index:999}.Tip_disclaimer__YvbE6 a{color:inherit}
.PaymentSuccess_Container__29xhb{display:flex;flex-direction:column;align-items:center;text-align:center}.PaymentSuccess_Graphic__2D2cL{height:300px}
.AuthForm_Form__2Bbgu{box-sizing:border-box;background:#fff;max-width:500px;width:100%;min-height:400px;border-radius:10px;box-shadow:4px 4px 50px 29px rgba(34,60,80,.12);display:flex;flex-direction:column;padding:50px;padding-bottom:25px;margin-bottom:30px;position:relative;align-self:flex-start}.AuthForm_Logo__2q-_V{width:140px;cursor:pointer}
.Login_Container__wArQp{width:100%;height:100%;display:flex;justify-content:center;padding-top:20px;box-sizing:border-box;background-image:url(https://firebasestorage.googleapis.com/v0/b/giftyrates.appspot.com/o/media%2Fimg%2Flogin.jpg?alt=media&token=011e505a-d3fc-4e30-a2a5-e600b5aab79d);background-size:cover}.Login_errorText__3Gsv-{font-size:14px;font-weight:100;color:red}
.Register_Container__2vNx8{width:100%;height:100%;display:flex;justify-content:center;padding-top:20px;box-sizing:border-box;background-image:url(https://firebasestorage.googleapis.com/v0/b/giftyrates.appspot.com/o/media%2Fimg%2Fregister.jpg?alt=media&token=6291bbfb-8617-4e92-b77b-610881612ea9);background-size:cover;overflow-y:auto}.Register_TextField__oztjx{margin-bottom:20px !important}.Register_SignInBtn__2eVZM{width:100%}.Register_BoxBtnUp__2W6y4{width:100%}.Register_errorText__2WYUQ{font-size:14px;font-weight:100;color:red}.Register_Inputs__3DXkL{width:100%;display:flex;flex-direction:column;grid-gap:24px;gap:24px;margin-bottom:24px}
.Forgot_Container__3Loiw{width:100%;height:100%;display:flex;justify-content:center;padding-top:20px;box-sizing:border-box}.Forgot_errorText__2NQQT{font-size:14px;font-weight:100;color:red}
.UsernameInput_UsernameStatus__2zr60{margin-left:24px;color:#4bb543;transition:.3s all;font-size:14px;font-weight:bold;display:flex;align-items:center;grid-gap:6px;gap:6px;min-height:20px;position:absolute;bottom:-12px}.UsernameInput_UsernameStatus__2zr60.UsernameInput_error__gjfYe{color:red}
.CreateAccount_Container__1NnqJ{display:flex;flex-direction:column;align-items:center;padding:0 10%}.CreateAccount_Container__1NnqJ h3{font-weight:bold}.CreateAccount_ErrorText__x8lX1{font-size:14px;color:red;margin-top:10px}
.Notification_Container__1cmP6{width:90%;margin:10px auto;background-color:#d3d3d3;padding:10px 5%;box-sizing:border-box;border-radius:10px;display:flex;align-items:center;color:#000;cursor:pointer}.Notification_Text__1m23H{font-size:.8em;margin-left:10px;flex:1 1}
.Account_Container__1-BNz{flex:1 1;min-height:100%;display:flex;flex-direction:column;padding-top:15px;padding-bottom:50px;box-sizing:border-box;position:relative}h4{font-weight:bold}
.AccountTips_Container__3PB-J{flex:1 1;min-height:100%;display:flex;flex-direction:column;padding-top:15px;padding-bottom:50px;box-sizing:border-box;position:relative;overflow-y:auto}.AccountTips_QRCode__3ZX8x{border-radius:10px;margin-bottom:10px}
.TipsHistory_Container__3HSzg{background-color:#fff;width:90%;align-self:center;box-shadow:rgba(0,0,0,.1607843137) 0 3px 6px;border-radius:10px;padding:0 2.5%;box-sizing:border-box;min-height:100px;transition:.2s height;position:relative;padding-bottom:20px}.TipsHistory_Table__3ly_2{width:100%;border-collapse:separate;border-spacing:0 2px;table-layout:fixed}.TipsHistory_Table__3ly_2 tr td:nth-of-type(3){color:gray}.TipsHistory_Table__3ly_2 tr td:first-child{border-top-left-radius:10px;border-bottom-left-radius:10px}.TipsHistory_Table__3ly_2 tr td:last-child{border-top-right-radius:10px;border-bottom-right-radius:10px}.TipsHistory_Table__3ly_2 tr:nth-of-type(even):not(:first-child){background-color:#f2f2f2;border-radius:10px;overflow:hidden}.TipsHistory_Table__3ly_2 th{font-weight:bold}.TipsHistory_Table__3ly_2 td{padding:6px;text-align:center;font-size:.8em;width:auto}.TipsHistory_ArrowIcon__3DlzM{transition:.3s all;color:gray}
.EditAccount_Container__2_bLE{flex:1 1;min-height:100%;display:flex;flex-direction:column;margin-top:10px;padding-bottom:50px;box-sizing:border-box;position:relative;overflow-y:auto;margin:0 5%}.EditAccount_TopContainer__3UJPn{width:90%;align-self:center;padding-bottom:20px;margin-bottom:20px;border-bottom:1px solid gray}
.EditProfileCard_Container__3jJwT{background-color:#fff;margin:10px 0;border-radius:10px;box-shadow:rgba(0,0,0,.1607843137) 0 1px 6px;width:95%;align-self:center;padding:12px 0}.EditProfileCard_Img__piw1L{margin-left:12px;width:50px;height:50px;border-radius:50%;background-color:#d3d3d3;border:2px solid #d3d3d3;margin-left:4%;object-fit:cover}.EditProfileCard_ImgPlaceholder__1n9mg{width:50px;height:50px;border-radius:50%;background-color:#d3d3d3;margin-left:4%;display:flex;align-items:center;justify-content:center}
.ModalEditProfile_Img__1fY-g{width:35vw;height:35vw;border-radius:50%;border:2px solid #d3d3d3;margin-bottom:5px;max-width:250px;max-height:250px;position:relative;z-index:1;margin-top:10px}.ModalEditProfile_Img__1fY-g img{height:100%;width:100%;object-fit:cover;border-radius:50%}.ModalEditProfile_InputDisplayName__1w78V{all:unset;font:inherit;width:100%;background:none;text-align:center;resize:auto;white-space:wrap;overflow:hidden;height:35px;word-break:break-all}.ModalEditProfile_InputDisplayName__1w78V::-webkit-input-placeholder{color:#c9c9c9}.ModalEditProfile_InputDisplayName__1w78V::placeholder{color:#c9c9c9}.ModalEditProfile_InputAboutText__3xM1S{all:unset;font:inherit;width:80%;align-self:center;text-align:center;margin:10px 0;height:100px}.ModalEditProfile_TipContainer__2DBbs{background-color:#fff;width:90%;align-self:center;box-shadow:rgba(0,0,0,.1607843137) 0 3px 6px;border-radius:10px;padding:0 2.5%;box-sizing:border-box;min-height:100px;transition:.2s height;position:relative;padding-bottom:20px}.ModalEditProfile_TipCircles__2eelK{width:100%;justify-content:space-evenly;margin-top:12px}.ModalEditProfile_CustomInputError__1hADS{color:red;font-size:14px;text-align:center;margin:6px 5px}.ModalEditProfile_EditCircle__2aUbA{width:45px;height:45px;border-radius:50%;background-color:#05c3dd;color:#fff;display:flex;align-items:center;justify-content:center}
.Payouts_HeaderImage__27Cwj{margin-top:20px;height:75px}.Payouts_TextInput__3vt9I{margin-bottom:10px}.Payouts_BankDetails__1Vw5l{text-align:left;width:100%;max-width:400px}.Payouts_BankDetails__1Vw5l th{font-weight:600}.Payouts_error__1WUzm{font-size:.8em;font-weight:bold;color:red;margin:5px 0}
.AccountLayout_Container__1On_k{display:flex;flex-direction:column;height:100%;max-width:800px;margin:0 auto;position:relative}.AccountLayout_Container__1On_k main{flex:1 1;background-color:#f2f2f2;height:100%;overflow-y:auto;padding-bottom:40px}.AccountLayout_Container__1On_k header{display:flex;align-items:center;width:90%;align-self:center;padding-top:10px;padding-bottom:10px}.AccountLayout_Container__1On_k .AccountLayout_Logo__1SFeH{width:50%;object-fit:cover;padding-top:4px;margin-left:5%}.AccountLayout_Container__1On_k .AccountLayout_TopSheet__2BH9X{position:absolute;width:100%;height:250px;background-color:#fff;padding:25px 10px;padding-top:10px;box-sizing:border-box;border-bottom-left-radius:15px;border-bottom-right-radius:15px;box-shadow:rgba(0,0,0,.1607843137) 0 1px 6px;top:-300px;transition:.5s all;z-index:100;display:flex;flex-direction:column}.AccountLayout_Container__1On_k .AccountLayout_TopSheet__2BH9X .AccountLayout_SheetContainer__34Ec7{flex:1 1;margin-top:10px}.AccountLayout_Container__1On_k .AccountLayout_TopSheet__2BH9X.AccountLayout_active__3vmXa{top:0}
