.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h3 {
    font-weight: bold;
}

.StepCircle {
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #05c3dd;
    color: #05c3dd;
    border-radius: 50%;
}