.Container {
    background-color: #fff;
    width: 90%;
    align-self: center;
    box-shadow: #00000029 0 3px 6px;
    border-radius: 10px;
    padding: 0 2.5%;
    box-sizing: border-box;
    min-height: 100px;
    transition: 0.2s height;
    position: relative;
    padding-bottom: 20px;
}

.Table {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 2px;
    table-layout: fixed;

    tr {
        // DATE
        td:nth-of-type(3) {
            color: gray;
        }

        td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    tr:nth-of-type(even):not(:first-child) {
        background-color: #f2f2f2;
        border-radius: 10px;
        overflow: hidden;
    }

    th {
        font-weight: bold;
    }

    td {
        padding: 6px;
        text-align: center;
        font-size: 0.8em;
        width: auto;
    }

    // th:nth-of-type(2) {
    //     width: 120px;
    // }

    // td:nth-of-type(2) {
    //     width: 50px !important
    // }
}

.ArrowIcon {
    transition: 0.3s all;
    color: gray
}
